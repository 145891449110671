import VideoPlayer from "./VideoPlayer";
import "./componentcss/sectionmarketing.css";

const SectionMarketing = () => {
  return (
    <div className="fullwidth">
      <div className="teal">
        <div className="marketing-section">
          <div className="videoplayer">
            <VideoPlayer />
          </div>
          <div className="marketing-text">
            <h1 className="marketing-h1">Next-Level Balloon Art!</h1>
            <h5 style={{color:'red'}}>Unfortunately, Kevin is abroad at the moment and will be unavailable for events until further notice. We may not always be able to respond to event requests made through this website.</h5>

            <p class="marketing-p">
              Kevin is the Bay Area's world-class balloon artist with over 13
              years of experience!
            </p>
            <p class="marketing-p">
              His creative designs have mesmerized audiences all around the
              world. So get Kevin's world-famous balloon art for your next
              event, and don't miss out on decorating your event with masterful
              balloon decor! Create an amazing experience that your guests will
              talk about for years to come!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionMarketing;
